<template>
  <setting-password></setting-password>
</template>

<script>
import { mdiPlus, mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
// import { Drequest } from '@/plugins/Drequest'
import SettingPassword from './settings/SettingPassword.vue'

export default {
  data() {
    return {
      loading: false,
      user: {},
      panel: [0, 1, 2, 3]
    }
  },
  components: {
    SettingPassword
  },
  methods: {
  },

  setup() {

    var icons = {
      mdiAlertOutline,
      mdiCloudUploadOutline,
      mdiPlus,
    }
    return {
      icons,
    }
  },
}
</script>
